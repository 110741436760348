.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Importing Google fonts */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap");

/* **************** ROOT VAR **************** */
:root {
  --primary-white: #ffffff;
  --primary-yellow: #ffff00;
  --primary-dark: rgb(52, 58, 60);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-black: #000000;
}

body {
  font-family: "Oswald", sans-serif !important;
}

/* **************** NAVBAR **************** */
nav {
  z-index: 2;
}

/* **************** NAVBAR LOGO **************** */
.jg-logo {
  height: 62px;
  width: 62px;
  border-radius: 50%;
}

/* **************** NAVBAR LINKS **************** */
q nav a.nav-link {
  /* font-weight: 500; */
  letter-spacing: 0.9px;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  /* --primary-white needed to be overwriten with !important */
  color: var(--primary-white) !important;
  /* This will help make the links NOT bounce down when clicked */
  border-top: 4px solid var(--primary-dark);
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 4rem !important;
}

/* this will outline a bar above the links */
.navbar-nav > li > a.active,
.navbar-nav > li > a:focus {
  border-top: 4px solid var(--primary-yellow);
}

/* Hightlight the links when hovering */
.navbar-nav > li > a:hover {
  border-top: 4px solid var(--primary-yellow);
}

/* **************** HEADER **************** */

.header-wraper {
  position: relative;
  /* background url: THIS WILL CHANGE THE OUTLOOK WALLPAPER */
  background: url(./Image/macbook\ pro.jpeg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  color: var(--primary-white) !important;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-family: "Oswald", sans-serif; */
  z-index: 1;
}

.main-info > h1 {
  color: var(--primary-yellow) !important;
  text-transform: uppercase;
}

/* ------------- TYPED TEXT -------------- */
.typed-text {
  font-size: 2rem;
  /* color: var(--primary-white); */
}

@media (max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/* =============== HEADER MAIN OFFER BUTTON ================ */
.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 10px 12px 12px 12px;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition: 0.2s ease-in-out;
}

/* =============== PARTICLES JS ================ */
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* **************** ABOUT ME PROFILE PIC **************** */
.photo-wrap {
  display: flex;
  justify-content: center;
  padding: 2px;
  margin-bottom: 32px;
}

.img-thumbnail-profile {
  width: 14rem;
  height: 20rem;
  border-radius: 20%;
  padding: 0.2rem;
  border: 1px solid var(--primary-red);
}

.header-aboutme {
  color: var(--primary-hover-red);
  text-transform: uppercase;
}

@media (max-width: 468px) {
  .img-thumbnail-profile {
    width: 10rem;
    height: 14rem;
  }
}

/* **************** SERVICES **************** */
.services {
  text-align: center;
  padding-bottom: 2rem;
  background: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-hover-red);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-yellow);
  /* background: var(--primary-red); */
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1, 4rem;
  color: var(--primary-hover-red);
  /* background: var(--primary-white); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-red);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-hover-red);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

.services .box:hover p {
  color: var(--primary-white);
}

/* **************** EXPERIENCE **************** */

.experience {
  background: var(--primary-light-grey);
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: var(--primary-red);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}

.timeline-block-right {
  float: right;
}

.timeline-block-left {
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid var(--primary-dark);
  background: var(--primary-yellow);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: var(--primary-dark-grey);
  direction: ltr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: var(--primary-red);
  font-weight: 900;
}

/* -------------- MEDIA -------------- */

@media (max-width: 768px) {
  .experience .container {
    padding-left: 0;
  }
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
}

/* **************** PORTFOLIO **************** */

.portfolio-wrapper {
  /* background: var(--primary-light-grey); */
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-red);
}

.portfolio-image {
  width: 15rem;
  height: 8rem;
  border: 1px solid var(--primary-red);
  padding: 0.5rem 0.5rem 0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

/* **************** OVERFLOW **************** */

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 15rem;
  height: 8rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.5s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 3.6rem;
  color: var(--primary-red);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.popupbox-wrapper {
  margin-top: 5rem;
}

/* **************** OVERFLOW POPUPBOX **************** */

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-dark-grey);
}

.hyper-link:hover {
  color: var(--primary-red);
}

/* **************** MEDIA **************** */

@media (max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media (max-height: 570px) {
  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media (max-height: 280px) {
  .popupbox-wrapper {
    height: 90%;
  }

  .portfolio-image-popupbox {
    width: 30%;
  }
}

/* **************** CONTACTS COMPONENT **************** */

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}
.contact-me {
  color: var(--primary-yellow);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

/* **************** INPUTS **************** */

.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-yellow);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus,
.contacts textarea:focus {
  background: transparent;
  color: var(--primary-red);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-yellow);
  display: inline-block;
}

input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

/*- MEDIA -*/

@media (max-width: 768px) {
  .contacts {
    overflow: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/* ************* FOOTER COMPONENT ************* */

.footer {
  background: var(--primary-black);
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-yellow);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}
